<template>
    <div class="table" style="height: 100%; width: 90%; margin-left: auto; margin-right: auto;">
        <div style="height: 20%; width: 90%; margin-left: auto; margin-right: auto;">
        <headers></headers>
        </div>
        <div style="height: 80%; width: 90%; margin-left: auto; margin-right: auto;">
        <ag-grid-vue
          id="myGrid"
          style="width: 100%; height: 700px;"            
          class="ag-theme-alpine"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :pagination="true"
          :paginationAutoPageSize="false"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :frameworkComponents="frameworkComponents"
          :localeText="localeText" 
          :rowSelection="rowSelection"
          @selection-changed="getSelectedRows"
        >
        </ag-grid-vue> </div>
        <!-- <div class="end-group">
          <b-button class="end-btn" variant="primary" @click="deleteDisruptions">Terminer</b-button>
          <b-form-group>
            <input type="checkbox" class="btn-ckeck"/>
          </b-form-group>
        </div> -->
        <div class="group-btn">
          <b-button variant="primary" class="pdf-btn" @click="createPDF"><p class="pdf-btn-content"><b-icon icon="printer-fill"></b-icon> <span style="margin-left: 8px">Imprimer la selection (pdf)</span></p></b-button>
          <b-link class="download-link" @click="csvExport">Télécharger (csv)</b-link>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
//import "ag-grid-enterprise";
import "ag-grid-community";
import { AgGridVue } from 'ag-grid-vue';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Header from './Header.vue'
import moment from 'moment'
import filterParams from '../Function.js'
import AG_GRID_LOCALE_FR from '../Locale'
// import config from '../config.js'

export default {
    data() {
        return {
            columnDefs: null,
            rowData: [],
            rowSelection: null,
            gridApi: null,
            columnApi: null,
            gridOptions: null,
            defaultColDef: null,
            frameworkComponents: null,
            localeText: null,
        }
    },
    components: {
        AgGridVue,
        'headers': Header,
    },
    computed: {
      disruptions: function() {
        return this.$store.state.disruptions
      },
      loading: function() {
        return this.$store.state.loading
      }
    },
    beforeMount() {
        this.gridOptions = {}
        this.columnDefs = [
            { 
                headerName: "Début",
                field: 'debut',
                filter: 'agDateColumnFilter',
                sortable: true,
                width: 120,
                filterParams: filterParams,
                comparator: this.dateComparator,
                sort: 'desc'
            },
            { 
                headerName: "Fin", 
                field: 'fin',
                filter: 'agDateColumnFilter',
                sortable: true,
                width: 110,
                filterParams: filterParams,
                comparator: this.dateComparator,
            },
            { headerName: "Type", field: 'type', filter: true, 
            width: 120
            },
            { headerName: "Source", field: 'source', filter: true, 
            width: 120
            },
            { headerName: "Localisation", field: 'localisation', filter: true, 
            width: 285
            },
            { headerName: "Alerte", field: 'alert', filter: true, resizable: true, 
            width: 100
            },
            { 
              headerName: "Message", 
              field: 'message', 
              filter: true,
              width:  400, 
            
              cellRenderer: (params) => {
                  const route = {
                    name: "Detail",
                    params: params.data
                  };
                  const link = document.createElement("a");
                  link.href = this.$router.resolve(route).href;
                  link.innerText = params.value;
                  link.addEventListener("click", e => {
                    e.preventDefault();
                    // let routeData = this.$router.push({
                    //   name: route.name, //use name for router push
                    //   params: route.params,
                    // });
                    localStorage.setItem('params', JSON.stringify(route.params))
                    // let routeData = this.$router.resolve({path: 'detail'});
                    window.open(link.href, '_blank');
                  });
                  
                  return link;

              }
            },
            // { 
            //   headerName: 'Actions', 
            //   field: 'actions', 
            //   cellRenderer: (params) => {
            //     var btn = document.createElement("BUTTON")
            //     btn.innerHTML = "Terminer"
            //     btn.className = "end-btn"
            //     var now = moment().format('YYYY-MM-DD')
            //     var date_now = moment(now)
            //     if (params.data['fin']) {
            //       var fin = params.data['fin']
            //       var date_fin = moment(fin).format('YYYY-MM-DD')
            //       if (moment(date_fin).isAfter(date_now)) {
            //         btn.addEventListener("click", e => {
            //           e.preventDefault();
            //           alert("Je fais un test "+JSON.stringify(params.data))
            //         })
            //         return btn
            //       }
            //     }
            //   }     
            // }
        ];
        // this.frameworkComponents = {
        //   Btn: Btn
        // }
        this.localeText = AG_GRID_LOCALE_FR;
        this.defaultColDef = {
          sortable: true,
          filter: true,
        };
        this.rowSelection = 'multiple'
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },

    methods: {
        onGridReady() {
          const updateData = (data) => {
            var listData = []
            var i = 0
            for (i in data){
              for (var j in data[i]) {
              var k = data[i][j];
              var s = k.replace(/\\n/g, "")  
               .replace(/\\'/g, "\\'")
               .replace(/\\"/g, '\\"')
               .replace(/\\&/g, "\\&")
               .replace(/\\r/g, "\\r")
               .replace(/\\t/g, "\\t")
               .replace(/\\b/g, "\\b")
               .replace(/\\f/g, "\\f");
              
                s = s.replaceAll('\n','\\u003cbr\\u003e'); 
                var parData = JSON.parse(s);

                if (parData['visibility'] === 'yes' || parData['visibility'] === 'no' || parData['visibility'] === 'similar' || parData['visibility'] === 'potential'){
                  var arrData = {
                    'id': parData['unique disruption number'],
                    'debut': parData['actual start date'],
                    'fin': parData['actual end date'],
                    'source': this.getElement(parData['source']),
                    'localisation': parData['options']['route'],
                    'type': parData['disruption type'],
                    'alert': this.getElement(parData['options']['alert']),
                //    'message': typeof(parData['public description']) == 'string' ? parData['public description'] : parData['public description']['fr'],
                    'message': parData['public description'],
                    'geolocation': parData['geolocation'],
                    'date_debut': parData['planned start date'],
                    'date_fin': parData['planned end date']
                  }
                  
                  if (arrData['geolocation'].length === 2) {
                    // console.log(arrData['id'])
                    //   console.log(arrData['geolocation'])
                    if (typeof(arrData['geolocation'][0]) !== 'number') {
                      if (typeof(arrData['geolocation'][0][0]) !== 'number') {
                      
                      arrData['geolocation'] = arrData['geolocation'][0].concat(arrData['geolocation'][1])
                    //console.log(arrData['id'])
                      }
                    }
                  }
                  else if (typeof(arrData['geolocation']) === 'object') {
                    if (arrData['geolocation'].x != null || arrData['geolocation'].latitude != null || arrData['geolocation'].lat != null) {
                      arrData['geolocation'] = [parseFloat(arrData['geolocation'].x), parseFloat(arrData['geolocation'].y)]
                 //   console.log(arrData['geolocation'])
                    }
                  }
                
                if (arrData["localisation"] === null || arrData["localisation"] === "") {
                  if (parData["address"] !== null || parData["address"] !== "") {
                    arrData["localisation"] = parData["address"]   
                  }               
                }

                if ('alert' in parData['options']) {
                  if ((parData['options']["alert"] === null) || (parData['options']["alert"] === "")) {
                    arrData["alert"] = "non"
                  }
                  else arrData["alert"] = arrData["alert"]
                }
                else arrData["alert"] = "non" 
                if (typeof(arrData['message']) === 'string' && arrData['message'] === "") arrData['message'] = "Aucun message à montrer"
                  else if (typeof(arrData['message']) === 'string' && arrData['message'] !== "") arrData['message'] = arrData['message']
                    else if ((arrData['message'] !== null) && (arrData['message'] !== "")) {
                      if (arrData['message']['fr']) arrData['message'] = arrData['message']['fr']
                        else {
                          arrData['message'] = arrData['message']['FR']
                        }
                      }
                else {                    
                  arrData['message'] = arrData['message']
                }


                  if (arrData['type'] === 'road_works') arrData['type'] = 'Travaux'
                  if (arrData['type'] === 'road_closure') arrData['type'] = 'Route fermée'
                  if (arrData['type'] === 'serious_hazard') arrData['type'] = 'Danger grave'
                  if (arrData['type'] === 'hazard') arrData['type'] = 'Danger'
                  if (arrData['type'] === 'winter_traffic') arrData['type'] = 'Neige / Verglas'
                  if (arrData['type'] === 'flood') arrData['type'] = 'Inondation'

                  if ((arrData['debut'] !== null) && (arrData['debut'] !== "")){

                    if (arrData['debut'].includes('-')) arrData['debut'] = moment(arrData['debut']).format('DD/MM/YYYY')
                    
                    else {
                      if (moment(arrData['debut'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData['debut']) arrData['debut'] = arrData['debut'].substr(0,10)
                      else {

                        arrData['debut'] = moment(arrData['debut']).format('DD/MM/YYYY')
                      }
                    }
                  }
                  else if ((arrData['date_debut'] !== null) && (arrData['date_debut'] !== "")) {
                    if (arrData['date_debut'].includes('-')) arrData['debut'] = moment(arrData['date_debut']).format('DD/MM/YYYY')
                    else {
                        if (moment(arrData['date_debut'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData['date_debut']) arrData['debut'] = arrData['date_debut'].substr(0,10)
                          else {
                            arrData['debut'] = moment(arrData['date_debut']).format('DD/MM/YYYY')
                          }
                    }                    
                  }
                  else {
                    arrData['debut'] = arrData['debut']                    
                  }

                  if ((arrData['fin'] !== null) && (arrData['fin'] !== "")) {
                    if (arrData['fin'].includes('-')) arrData['fin'] = moment(arrData['fin']).format('DD/MM/YYYY')
                    else {
                      if (moment(arrData['fin'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData['fin']) arrData['fin'] = arrData['fin'].substr(0,10)
                      else {
                      arrData['fin'] = moment(arrData['fin']).format('DD/MM/YYYY')
                      }
                    }
                  }
                  else if ((arrData['date_fin'] !== null) && (arrData['date_fin'] !== "")) {
                    if (arrData['date_fin'].includes('-')) arrData['fin'] = moment(arrData['date_fin']).format('DD/MM/YYYY')
                    else {
                      if (moment(arrData['date_fin'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData['date_fin']) arrData['fin'] = arrData['date_fin'].substr(0,10)
                      else {
                      arrData['fin'] = moment(arrData['date_fin']).format('DD/MM/YYYY')
                      }
                    }
                  }                  
                  else {
                    arrData['fin'] = arrData['fin']
                  }

                  listData.push(arrData)
                  this.rowData = listData
                }
              }
            }
          };
          fetch(window.url)
            .then((resp) => resp.json())
            .then((data) => updateData(data));
        },
        dateComparator(date1, date2) {
          var date1Number = this._monthToNum(date1);
          var date2Number = this._monthToNum(date2);

          if (date1Number === null && date2Number === null) {
            return 0;
          }
          if (date1Number === null) {
            return -1;
          }
          if (date2Number === null) {
            return 1;
          }

          return date1Number - date2Number;
        },

        // HELPER FOR DATE COMPARISON
        _monthToNum(date) {
          if (date === undefined || date === null || date.length !== 10) {
            return null;
          }

          var yearNumber = date.substring(6, 10);
          var monthNumber = date.substring(3, 5);
          var dayNumber = date.substring(0, 2);

          var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
          // 29/08/2004 => 20040829
          return result;
        },
        getSelectedRows() {
          var selectedNodes = this.gridApi.getSelectedNodes();
          var selectedData = selectedNodes.map( node => node.data );
          return selectedData
          // const selectedDataStringPresentation = selectedData.map(node => JSON.stringify(node)).join(', ');  
          // return selectedDataStringPresentation
        },
        getElement(disruption) {
          if (disruption) {
            if (disruption.includes("Orléans")) return "AT Orléans"
            if (disruption.includes("Montargis")) return "AT Montargis"
            if (disruption.includes("Gien")) return "AT Gien"
            if (disruption.includes("Pithiviers")) return "AT Pithiviers"
            if (disruption.includes("Tipi")) return "Tipi"
            if (disruption.includes("Sully")) return "AT Sully"
            else return disruption
          }
        },
        key_fields() {
            this.fields.forEach(element => {
                return element
            })
        },
        createPDF () {
            var doc = new jsPDF('p', 'pt', 'a4');
            var date_f = moment().format('DD/MM/YYYY HH:mm')
            doc.setFontSize(10)
            doc.setTextColor('#C23422')
            doc.text('Le Havre Seine Métropole - Historique des perturbations',200,25)
            doc.text('ViaPro',30,25)
            doc.setFontSize(10)
            doc.text(date_f,500,25)
            doc.autoTable({
            // html: '#my-table',
            theme: 'striped',
            headStyles: {
                fillColor: '#C23422',
            },
            styles: {
                fontSize: 6, 
            },
            bodyStyles: {
                fillColor: '#E8B7B5'
            },
            alternateRowStyles: {
                fillColor: '#F6E3E3'
            },
            body: this.getSelectedRows(), 
            columns: [
                { header: 'Début', dataKey: 'debut' },
                { header: 'Fin', dataKey: 'fin' },
                { header: 'Type', dataKey: 'type' },
                { header: 'Source', dataKey: 'source' },
                { header: 'Localisation', dataKey: 'localisation' },
                { header: 'Alerte', dataKey: 'alert' },
                { header: 'Message', dataKey: 'message' },
            ],
            })
            
            const pageCount = doc.internal.getNumberOfPages();

            // For each page, print the page number and the total pages
            for(var i = 1; i <= pageCount; i++) {
                // Go to page i
                doc.setPage(i);
                //Print Page 1 of 4 for example
                doc.setFontSize(7)
                doc.text('Page ' + String(i) + ' sur ' + String(pageCount),600-40,880-50,null,null,"right");
            }
       //   doc.save("cd45_perturbations.pdf");
          doc.save("viapro-lhsm-history-"+String(date_f)+".pdf");
          // doc.autoPrint();
          // window.open(doc.output('bloburl'), '_blank');
          // window.close();
        },
        
        csvExport() {
          var date_f = moment().format('DD/MM/YYYY HH:mm')
          var params = { fileName: "viapro-lhsm-history-"+String(date_f)+".csv"};
          this.gridApi.exportDataAsCsv(params);
        // let csvContent = "data:text/csv;charset=utf-8,";
        // csvContent += [
        //     Object.keys(this.rowData[0]).join(";"),
        //     ...this.selected.map(item => Object.values(item).join(";"))
        // ]
        //     .join("\n")
        //     .replace(/(^\[)|(\]$)/gm, "");

        // const data = encodeURI(csvContent);
        // const link = document.createElement("a");
        // link.setAttribute("href", data);
        // link.setAttribute("download", "export.csv");
        // link.click();
        },
    }
}
</script>

<style lang="scss">
@import "../css/_var";
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

    .table {
      margin-top: 30px;
    }
    .table-content {
      width: 100%;
    }
    .ag-header{
      background-color: #C23422 !important;
    }
    // .ag-center-cols-container {
    //     width: 100% !important; 
    //     height: 126px;
    // }
    .pagination {
      margin-top: 50px;
    }
    .ag-row-even {
        background-color: #F6E3E3  !important;
    }
    .ag-row-odd {
        background-color: #E8B7B5 !important;
    }
    .ag-row-selected {
        background-color: rgba(33, 150, 243, 0.3) !important;
    }
    .checkbox {
      text-align: center !important;
      vertical-align: middle !important;
    }
    .sr-only {
      display: none;
    }
    .group-btn {
      position: relative;
      margin: auto;
      text-align: center;
      margin-top: 30px;
      margin-left: 100px;
      .pdf-btn {
        background-color: #2870C0;
        border-radius: 4px;
        width: 300px;
        font-family: Arial, Helvetica, sans-serif;
        margin-right: 30px;
        font-weight: bold;
        height: 28px;
        text-align: center;
        position: relative;
        &:hover {
          background-color: #2870C0;
        }
        .pdf-btn-content {
          font-size: 15px;
          margin: 0 auto;
          text-align: center;
          margin-top: -13px;
        }
      }

      // .mail-btn {
      //   background-color: #7032A0 !important;
      //   border-radius: 4px;
      //   width: 300px;
      //   font-family: Arial, Helvetica, sans-serif;
      //   margin-right: 10px;
      //   font-weight: bold;
      //   height: 28px;
      //   text-align: center;
      //   position: relative;
      //   .mail-btn-content {
      //     font-size: 15px;
      //     margin: 0 auto;
      //     text-align: center;
      //     margin-top: -13px;
      //   }
      // }
      .download-link {
        color: black;
        margin-right: 150px;
        &:hover {
          color: #2870C0;
        }
      }
    }
    // .end-group {
    //   width: 100px;
    //   margin-left: 101%;
    //   margin-top: -340px;
    //   .end-btn {
    //     background-color: #2870C0;
    //     border-color: #2870C0;
    //     margin-top: -645px;
    //     &:hover {
    //       background-color: #2870C0;
    //       outline: none;
    //     }
    //     &:focus {
    //       background-color: #2870C0;
    //       outline-color: #2870C0;
    //     }
    //   }
    // }
    
    @media screen and (min-width: 900px) and (max-width: 1100px){
      .end-group {
        width: 100px;
        margin-left: 101%;
        margin-top: -380px;
        position: relative;
      }  
    }

    @media screen and (min-width: 300px) and (max-width: 900px){
      .end-group {
        width: 100px;
        margin-left: 101%;
        margin-top: -380px;
        position: relative;
      }  
    }

    @media print {
        .noPrint{
          display:none;
        }
      }
      h1{
        color:#f6f6;
      }


</style>