<template>
  <div class="container">
    <headers></headers>
    <div class="card">
      <div class="c-content">
        <map-vue :geolocation="getCurrentArray[0]['geolocation']" :message="getCurrentArray[0]['message']"></map-vue>
        <table class="table table-striped l-table">
          <tbody>
            <tr>
              <th>Début</th>
              <td> {{ getCurrentArray[0]['date_debut'] }}</td>
            </tr>
            <tr>
              <th>Fin</th>
              <td>{{ getCurrentArray[0]['date_fin'] }}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{{ getCurrentArray[0]['type'] }}</td>
            </tr>
            <tr>
              <th>Source</th>
              <td>{{ getCurrentArray[0]['source'] }}</td>
            </tr>
            <tr>
              <th>Localisation</th>
              <td>{{ getCurrentArray[0]['localisation'] }}</td>
            </tr>
            <tr>
              <th>Agence</th>
              <td>{{ getCurrentArray[0]['agence'] }}</td>
            </tr>
          </tbody>
        </table>
        <div class="table2">
          <span>id = {{ getCurrentArray[0]['id'] }}</span>
          <table class="table table-striped l-table2">
            <thead>
              <tr>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ getCurrentArray[0]['message'] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div style="margin-left: 5%" class="p-group">
        <span><b>Modifications</b></span>
        <div style="margin-top: 25px">
          <span></span>
          <span style="margin-left: 8%">Création</span>
        </div>
        <br />
        <div style="position: absolute">
          <span>03/04/2021 16:23</span>
        </div>
        <div
          style="float: right; margin-right: 24%; font-family: 'Arial'"
          class="p-group-span"
        >
          <span>
            Champ modifié: <b>Message public </b>par <b>Viapro - AT Montargis</b> <br />
            Valeur précédente: « RD 623 -Raccordement électrique du collège – Route fermée
            – Dadonville » <br />
            Nouvelle valeur : « RD 623 -Raccordement électrique du collège -Circulation
            alternée –Dadonville »
          </span>
        </div>
        <div
          style="margin-left: 18.4%; margin-top: 7%; margin-bottom: 15px"
          class="p-group-span2"
        >
          <span>
            Champ modifié : <b>Date de fin </b>par <b>ViaPro - AT Montargis</b> <br />
            Valeur précédente : 05/04/2021 18:00 <br />
            Nouvelle valeur : 06/04/2021 23:25 <br />
          </span>
        </div>
      </div>
      <div>
        <b-row style="margin-left: 5%">
          <b-col sm="2" class="b-textarea">
            <label for="textarea-small"><b>Commentaire </b></label>
          </b-col>
          <b-col sm="8">
            <b-form-textarea
              id="textarea-small"
              size="sm"
              style="border-radius: 12px"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-button
          variant="success"
          style="
            margin-right: 16%;
            float: right;
            margin-top: 10px;
            border-radius: 7px;
            background-color: #70ad47;
            border-color: #70ad47;
            width: 110px;
          "
          class="btn-val"
        >
          <font-awesome-icon icon="check"></font-awesome-icon> Valider</b-button
        >
      </div>
    </div> -->
    <div class="group-btn2">
      <b-button variant="primary" class="pdf-btn2" @click="createPDF">
        <p class="pdf-btn-content2">
          <b-icon icon="printer-fill"></b-icon>
          <span style="margin-left: 8px">Imprimer (pdf)</span>
        </p>
      </b-button>
      <!-- <b-button class="mail-btn2"><p class="mail-btn-content2"><b-icon-envelope-fill></b-icon-envelope-fill><span style="margin-left: 8px">Envoyer</span></p></b-button> -->
      <b-link :href="'/'" class="download-link2">Fermer la fiche</b-link>
    </div>
  </div>
</template>
<script>
import Header from "./Header.vue"
import MapVue from "./Map.vue"
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
// import config from '../config.js'
export default {
  data() {
    return {
    };
  },
  components: {
    headers: Header,
    "map-vue": MapVue,
  },
  computed: {
    getCurrentArray() {
      var arrData = []
      if(localStorage.getItem('params')) {
        arrData.push(JSON.parse(localStorage.getItem('params')))
      }
      if ((arrData[0]['date_debut'] !== null) && (arrData[0]['date_debut'] !== "")) {
        if (arrData[0]['date_debut'].includes('-')) arrData[0]['date_debut'] = moment(arrData[0]['date_debut']).format('DD/MM/YYYY HH:mm:ss')
        else {
          if (moment(arrData[0]['date_debut'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData[0]['date_debut']) arrData[0]['date_debut'] = arrData[0]['date_debut'].substr(0,20)
          else {
            arrData[0]['date_debut'] = moment(arrData[0]['date_debut']).format('DD/MM/YYYY HH:mm:ss')
          }
        }
      }
      if ((arrData[0]['date_debut'] !== null) && (arrData[0]['date_debut'] !== "")) {
        if (arrData[0]['date_fin'].includes('-')) arrData[0]['date_fin'] = moment(arrData[0]['date_fin']).format('DD/MM/YYYY HH:mm:ss')
        else {
          if (moment(arrData[0]['date_fin'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') === arrData[0]['date_fin']) arrData[0]['date_fin'] = arrData[0]['date_fin'].substr(0,20)
          else arrData[0]['date_fin'] = moment(arrData[0]['date_fin']).format('DD/MM/YYYY HH:mm:ss')
        }
      }
      return arrData
    }
  },
  methods: {
    createPDF () {
        var doc = new jsPDF('p', 'pt', 'a4');
        var date_f = moment().format('DD/MM/YYYY HH:mm')
        doc.setFontSize(10)
        doc.setTextColor('#C23422')
        doc.text('Le Havre Seine Métropole - Historique des perturbations',200,25)
        doc.text('ViaPro',30,25)
        doc.setFontSize(10)
        doc.text(date_f,500,25)
        doc.autoTable({
        // html: '#my-table',
        theme: 'striped',
        headStyles: {
            fillColor: '#C23422',
        },
        styles: {
            fontSize: 6, 
        },
        bodyStyles: {
            fillColor: '#E8B7B5'
        },
        alternateRowStyles: {
            fillColor: '#F6E3E3'
        },
        body: this.getCurrentArray, 
        columns: [
            { header: 'Début', dataKey: 'debut' },
            { header: 'Fin', dataKey: 'fin' },
            { header: 'Type', dataKey: 'type' },
            { header: 'Source', dataKey: 'source' },
            { header: 'Localisation', dataKey: 'localisation' },
            { header: 'Agence', dataKey: 'agence' },
            { header: 'Message', dataKey: 'message' },
        ],
        })
        const pageCount = doc.internal.getNumberOfPages();

        // For each page, print the page number and the total pages
        for(var i = 1; i <= pageCount; i++) {
            // Go to page i
            doc.setPage(i);
            //Print Page 1 of 4 for example
            doc.setFontSize(7)
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount),600-40,880-50,null,null,"right");
        }
        doc.save("viapro-lhsm-history-"+String(date_f)+".pdf");
    }   
  }
};
</script>

<style lang="scss">
@import "../css/_var";

.card {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 3px !important;
  width: 100%;
  position: relative;
  background: $color-card;
  margin: auto;
  margin-top: 20px;
  box-shadow: $card-shadow-below;

  transition: transform 300ms;
  &:nth-child(3n + 1) {
    transition-delay: 100ms;
  }
  &:nth-child(3n + 2) {
    transition-delay: 200ms;
  }
  &:nth-child(3n + 3) {
    transition-delay: 300ms;
  }

  &.subcard {
    margin-top: 0;
    padding: 0 $card-padding;

    &.expanded {
      padding-top: $card-padding;
      padding-bottom: $card-padding;
    }
  }

  &.fullwidth {
    padding: $card-padding 0;
  }
  .c-content {
    .l-table {
      width: 28%;
      margin-left: 47%;
      float: left;
      height: 270px;
      margin-top: 0;
      th {
        background-color: #c23422 !important;
        color: white !important;
        width: 40%;
        text-align: center;
      }
      td {
        text-align: center;
      }
    }
    .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
      background-color: #F6E3E3;
    }
    .table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
      background-color: #E8B7B5;
    }
    .table2 {
      float: right;
      width: 25%;
      // margin-right: 5%;
      span {
        margin-left: 6%;
        margin-top: 24px;
        position: absolute;
      }
      .l-table2 {
        width: 20%;
        float: right;
        margin-top: 70px;
        margin-left: 40px;
        position: absolute;
        thead {
          background-color: #c23422 !important;
          color: white !important;
          text-align: center;
        }
        td {
          text-align: center;
        }
      }
    }
  }
  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .c-content {
      .table2 {
        float: right;
        width: 6%;
        // margin-right: 5%;
        span {
          margin-left: -14%;
          margin-top: 2%;
          position: absolute;
          font-size: 0.9em;
          // font-weight: bold;
        }
        .l-table2 {
          width: 20%;
          float: right;
          margin-top: 70px;
          margin-left: -15%;
          position: absolute;
          thead {
            background-color: #c23422 !important;
            color: white !important;
            text-align: center;
          }
          td {
            text-align: center;
          }
        }
      }      
    }
    .p-group {
      .p-group-span {
        margin-left: 28%;
      }
      .p-group-span2 {
        margin-left: 28% !important;
        margin-top: 32% !important;
      }
    }
    .b-textarea {
      margin-right: 8% !important;
    }
    .btn-val {
      margin-right: 9% !important;
    }
  }
}
.group-btn2 {
  position: relative;
  margin: auto;
  text-align: center;
  margin-top: 30px;
  .pdf-btn2 {
    background-color: #2870c0;
    border-radius: 4px;
    width: 200px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 30px;
    // margin-left: 10%;
    font-weight: bold;
    height: 30px;
    text-align: center;
    position: relative;
    .pdf-btn-content2 {
      font-size: 15px;
      margin: 0 auto;
      text-align: center;
      margin-top: -3px;
    }
  }
  .mail-btn2 {
    background-color: #7032a0 !important;
    border-radius: 4px;
    width: 200px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 10px;
    font-weight: bold;
    height: 30px;
    text-align: center;
    position: relative;
    .mail-btn-content2 {
      font-size: 15px;
      margin: 0 auto;
      text-align: center;
      margin-top: -3px;
    }
  }
}
</style>
